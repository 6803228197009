import React, { useState } from "react"
import { useForm } from "@formspree/react"
import jQuery from "jquery"

function ContactForm({ background, color, button }) {
  const [bgColour, setBgColour] = useState(button)
  const buttonStyle = {
    background: `${bgColour}`
  }
  const [state, handleSubmit] = useForm(`${process.env.GATSBY_FORMSPREE_KEY}`)

  if (state.succeeded) {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      jQuery(document).ready(function($) {
        $("#exampleModal").modal("show")
      })
    }
  }

  return (
    <>
      <div className="callback-form callback-services" style={{ background: background }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading">
                <h2>Request a <em>callback</em></h2>
                <span>find out everything you need</span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="contact-form" style={{ background: color }}>
                <form id="contact" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <fieldset>
                        <input name="name" type="text" className="form-control" id="name" placeholder="Full Name"
                               required />
                      </fieldset>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <fieldset>
                        <input name="email" type="text" className="form-control" id="email" pattern="[^ @]*@[^ @]*"
                               placeholder="E-Mail Address" required />
                      </fieldset>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <fieldset>
                        <input name="phone" type="number" className="form-control" id="number" placeholder="Phone"
                               required />
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <textarea name="message" rows="6" className="form-control" id="message"
                                  placeholder="Your Message" />
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <button
                          style={buttonStyle}
                          id="form-submit"
                          className="border-button"
                          type="submit"
                          disabled={state.submitting}
                          onMouseEnter={() => button ? setBgColour("#fff") : null}
                          onMouseLeave={() => button ? setBgColour(button) : null}
                        >
                          Send Message
                        </button>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
           aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Your request sent</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              We will contact you as soon as possible!
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn modal-submit btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactForm