import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Banner = () => {
  function SampleNextArrow() {
    return <div className="NextArrow" />
  }

  function SamplePrevArrow() {
    return <div className="PrevArrow" />
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  return (
    <div className="main-banner header-text" id="top">
      <div className="Modern-Slider">
        <Slider {...settings}>
          <div className="item item-1">
            <div className="img-fill">
              <div className="text-content">
                <h6>we are ready to help you</h6>
                <h4>How does ELD<br /> help you?</h4>
                <p>Trucking Industry Adjusts to ELD Mandate as Focus on HOS Compliance Sharpens. Providing easy to use
                  ELD/HOS solution, compliant with FMCSA regulations eCFR Title 49, part 395. Providing DVIR solution as
                  part of the ELD/HOS module.</p>
                <Link to="/contact/" className="filled-button">contact us</Link>
              </div>
            </div>
          </div>
          <div className="item item-2">
            <div className="img-fill">
              <div className="text-content">
                <h6>we are here to support you</h6>
                <h4>Hours of service</h4>
                <p>“Hours of service” refers to the maximum amount of time drivers are permitted to be on duty including
                  driving time, and specifies number and length of rest periods, to help ensure that drivers stay awake
                  and alert. In general, all carriers and drivers operating commercial motor vehicles (CMVs) will comply
                  with HOS regulations found in 49 CFR 395.</p>
                <Link to="/services/" className="filled-button">our services</Link>
              </div>
            </div>
          </div>
          <div className="item item-3">
            <div className="img-fill">
              <div className="text-content">
                <h6>we have a solid background</h6>
                <h4>Make your desire easy<br />by joining us</h4>
                <p>ELDKIT is a top provider of electronic logging devices and a logbook app, GPS fleet tracking and
                  AI-powered dispatch. We help truckers to become FMCSA compliant according to the new ELD Mandate and
                  Hours of Service rules..</p>
                <Link to="/about/" className="filled-button">learn more</Link>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default Banner