import * as React from "react"
import Layout from "../components/navigation/layout"
import Banner from "../components/banner"
import Request from "../components/request"
import Services from "../components/services"
import MoreInfo from "../components/more-info"
import ContactForm from "../components/ContactForm"
import Seo from "../components/seo"
import Analyze from "../components/Analyze"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Banner />
    <Request />
    <Services />
    <Analyze />
    <MoreInfo />
    <ContactForm />
  </Layout>
)

export default IndexPage
