import React from "react"
import { Link } from "gatsby"

const Request = () => {
  return (
    <div className="request-form">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h4>Request a call back right now ?</h4>
            <span>All the things that you want to be managed can be done with us.</span>
          </div>
          <div className="col-md-4">
            <Link to="/contact/" className="border-button">Contact Us</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Request