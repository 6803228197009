import React from "react"
import company from "../assets/images/more-info.jpg"
import { Link } from "../../.cache/gatsby-browser-entry"

const MoreInfo = () => {
  return (
    <div className="more-info">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="more-info-content">
              <div className="row">
                <div className="col-md-6">
                  <div className="left-image">
                    <img src={company} alt="company" />
                  </div>
                </div>
                <div className="col-md-6 align-self-center">
                  <div className="right-content">
                    <span>Who we are</span>
                    <h2>Get to know about <em>our company</em></h2>
                    <p>ELDKIT is a top provider of electronic logging devices and a logbook app, GPS fleet tracking and AI-powered dispatch. We help truckers to become FMCSA compliant according to the new ELD Mandate and Hours of Service rules.<br /><br />The ELDKIT Application is made with the use the latest technologies, which are the solutions for drivers and the changings in the Trucking industry.</p>
                    <Link to="/contact/" className="filled-button">Read More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MoreInfo