import React, { useState } from "react"
import service1 from "../assets/images/service_01.jpg"
import service2 from "../assets/images/service_02.jpg"
import service3 from "../assets/images/service_03.jpg"

const Services = () => {
  const textHours = "“Hours of service” refers to the maximum amount of time drivers are permitted to be on duty including driving time, and specifies number and length of rest periods, to help ensure that drivers stay awake and alert. In general, all carriers and drivers operating commercial motor vehicles (CMVs) will comply with HOS regulations found in 49 CFR 395."
  const textGPS = "With Fleet View, see location, safety events, HOS, and more on a single screen. Fleet App puts your fleet at your fingertips. Manage your business at any time, from anywhere on a mobile device or tablet. Keep tabs on your fleet by watching your vehicles, equipment in real-time. Notify customers of any delays and stay in the know for when your fleet arrives or departs with instant email alerts."
  const textIFTA = "IFTA Fuel Tax Reporting is a great feature for companies using the ELDKIT. IFTA reporting allows you to download the state-by-state mileage of each vehicle, based on the odometer readings from the vehicles themselves. With the IFTA reporting feature, companies can streamline the process to prepare IFTA documents, and get rid of trip sheets."
  const [isReadHours, setIsReadHours] = useState(true)
  const [isReadGPS, setIsReadGPS] = useState(true)
  const [isReadIFTA, setIsReadIFTA] = useState(true)

  return (
    <div className="services">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading">
              <h2>ELDKIT <em>Services</em></h2>
              <span>Let's take a closer look at our services</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="service-item">
              <img className="service-item-img" src={service1} alt="service1" />
                <div className="down-content">
                  <h4>Hours of service</h4>
                  <p>{isReadHours ? textHours.slice(0, 140)+"..." : textHours}</p>
                  <button
                    onClick={()=>setIsReadHours(!isReadHours)}
                    className="filled-button"
                  >{isReadHours ? "Read More" : " Show Less"}
                  </button>
                </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="service-item">
              <img className="service-item-img" src={service2} alt="service2" />
                <div className="down-content">
                  <h4>GPS Tracking</h4>
                  <p>{isReadGPS ? textGPS.slice(0, 150)+"..." : textGPS}</p>
                  <button
                    onClick={()=>setIsReadGPS(!isReadGPS)}
                    className="filled-button"
                  >{isReadGPS ? "Read More" : " Show Less"}
                  </button>
                </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="service-item">
              <img className="service-item-img" src={service3} alt="service3" />
                <div className="down-content">
                  <h4>IFTA Reports</h4>
                  <p>{isReadIFTA ? textIFTA.slice(0, 150)+"..." : textIFTA}</p>
                  <button
                    onClick={()=>setIsReadIFTA(!isReadIFTA)}
                    className="filled-button"
                  >{isReadIFTA ? "Read More" : " Show Less"}</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services