import React, { useState } from "react"

function Analyze() {
  const [readMore, setReadMore] = useState(false)
  const extraContent = <div style={{marginBottom: "5%"}}>
    We wish to understand our customers so that we can help them optimize their business processes while having an
    ongoing conversation to refine and ensure we have lasting strategies for success. This sums up our mission statement
    – Understand to Optimize and Optimize to Succeed.
  </div>

  const linkName = readMore ? "Read Less" : "Read More"

  return (
    <div className="fun-facts">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="left-content">
              <span>philosophy of eld</span>
              <h2>Set your ideas in motion, <em>and see them take off</em></h2>
              <p>As we look forward to the future we look to set the pace of innovation while playing a role in
                environmental sustainability. We are doing this by having conversations with our customers and partners
                about the health of their business and operations.<br />
              </p>
              {readMore && extraContent}
              <button className="filled-button more" onClick={() => {
                setReadMore(!readMore)
              }}>{linkName}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Analyze